import React from "react"
import Layout from "../../components/layout";
import Seo from "../../components/SEO/Seo"
import "../../styles/servicios.scss"



export default function Servicios(props) {

    return (
            <Layout>
                <Seo title="Marketing & Content" pathname={props.location.pathname}/>
                <div className="cuerpo-servicios">
                    <section className="container primera-seccion cabecera-servicios">
                        <div className="caja-titulo">
                            <h1>Marketing & Content</h1>
                        </div>
                        <div className="caja-subtitulo">
                            <p className="destacado">Qué contar, cómo contarlo y dónde hacerlo para que tus accciones no acaben a la deriva en el océano digital.</p>
                        </div>
                    </section>               
                    <section className="container textos-servicio">
                        <div className="columna-foto" style={{backgroundImage: "url(/img/faro.webp)"}}></div>
                        <div className="columna-texto">
                            <p>Qué contar, cómo contar y dónde hacerlo 
                            para que tus acciones no se pierdan en la 
                            inmensidad del océano. </p>
                            <h3>Campañas de publicidad</h3>
                            <p>Desde la gráfica publicitaria a los medios: 
                            inbound marketing, campañas integrales y cuidadas. 
                            Buscamos peces en el mar que lleguen cargaditos de dinero: 
                            tu ROI.</p>
                            <h3>Contenidos</h3>
                            <p>Lo sabemos. Escribir un mensaje en una
                            botella puede parecer sencillo
                            pero a veces no sabes que poner.
                            Para que esto no te pase están
                            nuestros copys y nuestro
                            equipo de creación de contenidos:
                            textos persuasivos, imágenes impactantes,
                            videos corporativos, vídeos cortos...</p>
                            <h3>Producción de eventos</h3>
                            <p>Para que llegues guapo a la orilla. 
                            Sin despeinarte. Si tu empresa va a asistir 
                            a un evento y crees que nadas contracorriente: 
                            has llegado al mejor lugar. Allá donde lleves a tu 
                            empresa haz que tu marca destaque.</p>
                        </div>
                    </section>
                </div>
    </Layout>
    )
}